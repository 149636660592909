<style>
#datepicker {
  z-index: 1000;
}
.vs-table--tbody-table tr {
  font-family: sans-serif;
  font-size: smaller;
}
</style>
<template>
  <div class="vs-row">
    <template lang="html">
      <div>
        <vx-card actionable class="cardx" title="Utilization Reports CCF">
          <vs-row style="margin-bottom: 1%">
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="3"
              
            >
              <v-select
                style="z-index: 1000; margin-top: 4%; margin: 10px"
                class="w-full"
                placeholder="City"
                v-model="selected_city"
                :options="cities"
              ></v-select>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="3"
              v-if="getRole('cm4') || getRole('cmca')"
            >
              <v-select
                style="z-index: 1000; margin-top: 4%; margin: 10px"
                class="w-full"
                placeholder="City Classification"
                v-model="selected_city_code"
                :options="city_codes"
              ></v-select>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="3"
            >
              <div id="datepicker">
                <date-range-picker
                  ref="picker"
                  :opens="'right'"
                  :locale-data="{ firstDay: 1, format: 'DD-MMM-YYYY' }"
                  :minDate="null"
                  :maxDate="null"
                  :singleDatePicker="false"
                  :timePicker="false"
                  :timePicker24Hour="true"
                  :showWeekNumbers="false"
                  :showDropdowns="false"
                  :autoApply="false"
                  v-model="today_date"
                  style="width: 240px"
                ></date-range-picker>
                <!-- <datepicker placeholder="Select Date" v-model="today_date"></datepicker> -->
              </div>
            </vs-col>
            <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              vs-w="2"
            >
              <vs-button color="dark" type="filled" @click="getCMData()">GO</vs-button>
            </vs-col>
          </vs-row>
          <vs-table :data="utilization_data" v-if="loaded">
            <template slot="thead" class="exc">
              <vs-th>Spoc</vs-th>
              <vs-th>HF/NHF</vs-th>
              <vs-th>Utilization(%)</vs-th>
              <vs-th>Visitors(%)</vs-th>
              <vs-th>Enrollments | Complete Payments </vs-th>
              <vs-th>Total</vs-th>
              <vs-th
                >Total Calls|Total Unique Calls|Duration|
                Incoming|Outgoing</vs-th
              >
              <vs-th>Connected Calls</vs-th>
              <!-- <vs-th>MWB Call Count</vs-th>
              <vs-th>EWB Call Count</vs-th> --> 
              <!-- <vs-th>Untracked Incoming Calls | Outgoing Calls</vs-th> -->
              <!-- <vs-th>Missed Calls</vs-th> -->
              <vs-th>MWB Call Count|Connected Calls|Total Unique Calls</vs-th>
              <vs-th>EWB Call Count|Connected Calls|Total Unique Calls</vs-th>

              <vs-th>Long Duration Calls 30-60 Mins | > 60 Mins</vs-th>
              <vs-th>Total NUC | Duration</vs-th>
              <vs-th
                v-if="
                  this.timetoTimestamp(this.today_date.startDate) ===
                  this.timetoTimestamp(this.today_date.endDate)
                "
                >Attendance</vs-th
              >
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="item.spoc_name" v-for="item in data">
                <vs-td :data="item.spoc_name">
                  <div>
                    {{ item.spoc_name }}
                  </div>
                </vs-td>
                <vs-td :data="item.spoc_name">
                  <div>
                    {{item.user_tag}}
                  </div>
                </vs-td>

                <vs-td :data="item.call_utilization">
                  <div>{{ item.call_utilization }}</div>
                </vs-td>

                <vs-td :data="item.visitor_utilization">
                  <div>
                    {{ item.visitor_utilization }} |
                    {{ item.visit_engagements.length }}
                  </div>
                </vs-td>

                <vs-td>
                  <!-- <div>
                    {{ item.enrollments.length }}
                  </div> -->
                  <div>
                    <a
                      href="#"
                      @click="
                        EmitEvent('utilization-enrollment', [
                          item.actual_enrollments,
                          item.actual_enrollments.length,
                          item.spoc_name,
                        ])
                      "
                      >{{ item.actual_enrollments.length }}</a
                    >
                    -
                    <a
                      href="#"
                      @click="
                        EmitEvent('utilization-enrollment', [
                          item.enrollments_without_pending_payments,
                          item.enrollments_without_pending_payments.length,
                          item.spoc_name,
                        ])
                      "
                    >
                      {{ item.enrollments_without_pending_payments.length }}</a
                    >
                  </div>
                </vs-td>

                <vs-td :data="item.total_utilization">
                  <vs-chip :color="item.total_color">{{
                    item.total_utilization
                  }}</vs-chip>
                </vs-td>

                <vs-td :data="item.total_calls">
                  <div>
                    {{ item.total_calls }} | {{ item.total_unique_calls }} |
                    {{ item.total_duration }} | {{ item.incoming_call_count }} |
                    {{ item.outgoing_call_count }}
                  </div>
                </vs-td>

                <vs-td :data="item.connected_calls">
                  <div>
                    <a
                      href="#"
                      @click="
                        EmitEvent('utilization-mwb', [
                          item.connected_calls_arr,
                          item.spoc_name,
                          item.connected_calls,
                        ])
                      "
                      >{{ item.connected_calls }}</a
                    >
                  </div>
                </vs-td>
                <vs-td :data="item.mwb_call_count">
                  <div>
                    <a
                      href="#"
                      @click="
                        EmitEvent('utilization-mwb', [
                          item.mwb_arr,
                          item.spoc_name,
                          item.mwb_call_count,
                        ])
                      "
                      >{{ item.mwb_call_count }}</a
                    >
                    ({{ item.mwb_connected_call_count }})|
                    <a
                      href="#"
                      @click="
                        EmitEvent('utilization-mwb', [
                          item.connected_calls_arr,
                          item.spoc_name,
                          item.connected_calls,
                        ])
                      "
                      >{{ item.connected_calls }}</a
                    >|{{ item.total_mwb_unique_calls }}
                  </div>
                </vs-td>

                <!-- EWB Call Count|Connected Call|Unique Call -->
                <vs-td :data="item.ewb_call_count">
                  <div>
                    <a
                      href="#"
                      @click="
                        EmitEvent('utilization-ewb', [
                          item.ewb_arr,
                          item.spoc_name,
                          item.ewb_call_count,
                        ])
                      "
                      >{{ item.ewb_call_count }}
                    </a>
                    ({{ item.ewb_connected_call_count }}) |
                    <a
                      href="#"
                      @click="
                        EmitEvent('utilization-mwb', [
                          item.ewb_connected_calls_arr,
                          item.spoc_name,
                          item.ewb_connected_calls,
                        ])
                      "
                      >{{ item.ewb_connected_calls }}</a
                    >|{{ item.total_ewb_unique_calls }}
                  </div>
                </vs-td>

                <vs-td :data="item.call_30_60">
                  <div>
                    <a href="#" @click="EmitEvent('utilization-long')">{{
                      item.call_30_60
                    }}</a>
                    |
                    <a href="#" @click="EmitEvent('utilization-long')">{{
                      item.call_gt_60
                    }}</a>
                  </div>
                </vs-td>

                <vs-td :data="item.nuc_count">
                  <div>
                    <a
                      href="#"
                      @click="
                        EmitEvent('utilization-nuc', [
                          item.nuc_arr,
                          item.spoc_name,
                          item.nuc_count,
                        ])
                      "
                      >{{ item.nuc_count }}</a
                    >
                    | {{ item.nuc_duration }}
                  </div>
                </vs-td>

                <vs-td
                  v-if="
                    timetoTimestamp(today_date.startDate) ===
                    timetoTimestamp(today_date.endDate)
                  "
                  >{{ giveAttendanceTitle(item) }}</vs-td
                >
                <template class="expand-user" slot="expand">
                  <vs-row>
                    <vs-col
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="center"
                      vs-w="12"
                    >
                      <calllog :users="item"></calllog>
                    </vs-col>
                  </vs-row>
                </template>
              </vs-tr>
            </template>
          </vs-table>
        </vx-card>
      </div>
    </template>
  </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import calllog from "../components/pagesComponents/callLogComponent.vue";
// import Datepicker from "vuejs-datepicker";
import axios from "axios";
import constants from "../../constants.json";
import EventBus from "../components/eventbus.js";
import router from "@/router";
import vSelect from "vue-select";
export default {
  data() {
    return {
      city_codes: [],
      selected_city_code: "",
      itsCM4: false,
      loaded: false,
      today_date: {
        startDate: new Date(),
        endDate: new Date(),
      },
      selected_city: "",
      cities: [],
      all_visit_engagments: [],
      utilization_data: [],
      spocs: [],
      logged_in_user_id: "",
     
      
    };
  },
  components: {
    // Datepicker,
    calllog,
    DateRangePicker,
    "v-select": vSelect,
  },
  async mounted() {
    // this.reportGenerateAPI();
    this.getSpoc();
    // this.cm2Redirect();
  },
  watch: {
    // selected_city: function () {
    //   this.reportGenerateAPI();
    // },
    selected_city(val) {
      
      this.getCityClasificationCodes(val);
    },
  },
  methods: {
    getCityClasificationCodes(city) {
      let url = `${constants.SERVER_API}getCityClasificationCodes?city=${city}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getCityClasificationCodes", response);

          this.city_codes = this.sortArrayAlphabetically(response.data);
          console.log(this.city_codes, "fgfgfg");
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getCMData() {
      if(this.dateDiffInDays(this.today_date.startDate, this.today_date.endDate) <= 31){
        if(this.selected_city_code !== "" && this.getRole('cm4') || this.getRole('cmca')) {
          this.reportGenerateAPI();
        } else if( !this.getRole('cm4') || !this.getRole('cmca')) {
          this.reportGenerateAPI();
        }
      } else{
        this.$vs.notify({
          text: "Date range must be 31 days or less",
          color: "danger",
        });
      }
    },
    dateDiffInDays(start_date, end_date) {
      let tat_start_date = start_date;
      let tat_end_date = end_date;
      const a = new Date(tat_start_date),
        b = new Date(tat_end_date);
        // days_difference = this.dateDiffInDays(a, b);
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      // Discard the time and time-zone information.
      const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
      const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
      const days_difference = Math.floor((utc2 - utc1) / _MS_PER_DAY);
      return days_difference;
    },
    getRole(providedRole) {
      if (localStorage.getItem("role") === providedRole) {
        return true;
      } else {
        return false;
      }
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    timetoTimestamp(selected_date) {
      var timestamp = new Date(selected_date).setHours(0, 0, 0, 0) / 1000;
      return timestamp;
    },
    showDangerNotification(response) {
      this.$vs.notify({
        title: "Danger",
        text: response.data.message,
        color: "danger",
      });
    },
    showWarningNotification(response) {
      this.$vs.notify({
        title: "Warning",
        text: response.data.message + " in line " + response.data.line,
        color: "warning",
      });
    },
    showErrorNotification(error) {
      this.$vs.notify({
        title: "Danger",
        text: error.response,
        color: "danger",
      });
    },
    getSpoc() {
      // let url = `${constants.ADMIN_SERVER_API}getSpocs`;
      let url = ``;
      if (
        localStorage.getItem("role") === "cm1" ||
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "ac1" ||
        localStorage.getItem("role") === "cmca"
      ) {
        url = `${constants.ADMIN_SERVER_API}getSpocs`;
      } else if (localStorage.getItem("role") === "exed") {
        url = `${constants.ADMIN_SERVER_API}getExed`;
      }
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then(async (response) => {
          console.log("spocs123",response);
          this.logged_in_user_id = response.data.logged_in_user_id;
          this.spocs = response.data.spocs;
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          let unorderedCities = [];
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              unorderedCities.push(key);
            }
          }
          this.cities = this.sortArrayAlphabetically(unorderedCities);
          if (
            localStorage.getItem("role") === "cm4" ||
            localStorage.getItem("role") === "cmca"
          ) {
            this.itsCM4 = true;
            // this.selected_city = this.cities[0];
            this.selected_city = "";
          } else {
            this.spocs.forEach((spoc_data) => {
              if (spoc_data.id === this.logged_in_user_id) {
                // this.selected_city = spoc_data.city;
                this.selected_city = "";
              }
            });
            this.itsCM4 = false;
          }
          // this.reportGenerateAPI();
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    async reportGenerateAPI() {
      console.log("loading");
      this.$vs.loading();
      this.loaded = false;
      let spocjoin = [];
      //logged_in_user_id
      if (this.getRole("cm4") || this.getRole("hr")) {
        this.spocs.forEach((spoc) => {
          console.log("reportGenerateAPI spoc1",spoc.city_classification,this.selected_city_code);
          if (spoc.city_classification === this.selected_city_code) {
            console.log("reportGenerateAPI spoc2",spoc);
            spocjoin.push(spoc.id);
          }
          console.log("selected_city",this.selected_city);
          if(this.selected_city_code == "All"){
            console.log("All",spoc);
            if(spoc.city == this.selected_city){
              spocjoin.push(spoc.id);
            }
          }
          if(this.selected_city_code == "No City Classification"){
            console.log("No City Classification",spoc);
            if(spoc.city_classification == null){
              spocjoin.push(spoc.id);
            }
          }
        });
      } else {
        spocjoin.push(this.logged_in_user_id);
      }
      let spocsCollection = [];
      console.log("spocjoin",spocjoin);
      if(spocjoin.length == 0){
        setTimeout(() => {
          this.$vs.loading.close();
        }, 500);
      }
      for (let i = 0; i < spocjoin.length; i++) {
        let params = {
          spoc_id: spocjoin[i],
          from_date: this.timetoTimestamp(this.today_date.startDate),
          to_date: this.timetoTimestamp(this.today_date.endDate),
        };
        let response = await this.getUtilization(params);
        spocsCollection.push(response.data.data);
        console.log(response);
      }
      console.log(spocsCollection);
      this.calculateUtilization(spocsCollection);
      this.$vs.loading.close();
      // params.spoc_ids = spocjoin.join();
      // axios
      //   .get(`${constants.ADMIN_SERVER_API}getAllCallLogsForUtilization`, {
      //     headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
      //     params: params,
      //   })
      //   .then((response) => {
      //     if (response.data.code == 200) {
      //       this.removeUnwantedUsers(response.data.data);
      //       this.calculateUtilization(response.data.data);
      //     } else if (response.data.code == 403) {
      //       this.$vs.loading.close();
      //       this.showDangerNotification(response);
      //     } else {
      //       this.$vs.loading.close();
      //       this.showWarningNotification(response);
      //     }
      //   })
      //   .catch((error) => {
      //     this.handleError(error);
      //   });
    },
    getUtilization(params) {
      // let url = `${constants.ADMIN_SERVER_API}getSpocCallLogsForUtilization`;
      let url = `https://admin.milesforce.com/api/getSpocCallLogsForUtilization`;
      const promise = new Promise((resolve, reject) => {
        axios
          .get(url, {
            params: params,
            headers: {
              Authorization: `Bearer ${localStorage.userAccessToken}`,
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            this.handleError(error);
            reject();
          });
      });
      return promise;
    },
    removeUnwantedUsers(spocData) {
      let index_to_be_removed = [];
      spocData.forEach((spoc, index) => {
        // console.log(spoc.attendance.length);
        if (spoc.attendance.length === 0) {
          index_to_be_removed.push(index);
        }
      });
      // index_to_be_removed.forEach((ind) => {
      //   spocData.splice(ind, 1);
      // });
      // spocData.forEach
      if (index_to_be_removed.length > 0) {
        for (var i = spocData.length - 1; i >= 0; i--) {
          if (index_to_be_removed.indexOf(i) !== -1) {
            spocData.splice(i, 1);
          }
        }
      }
      // console.log(index_to_be_removed);
    },
    getPaymentStatus(enrollment) {
      let value = 0;
      if (
        enrollment.amount_paid >= enrollment.agreed_cost ||
        enrollment.amount_in_process >= enrollment.agreed_cost ||
        enrollment.amount_in_process + enrollment.amount_paid >=
          enrollment.agreed_cost
      ) {
        value = 2;
      } else {
        value = 1;
      }
      return value;
    },
    calculateUtilization(utilization_data) {
            let all_visit_engagments = [];
            for (var i = 0; i < utilization_data.length; i++) {
                // console.log(utilization_data[i]);
                var call_duration_sec = 0;
                var total_calls = 0;
                var all_call_logs = utilization_data[i].all_call_logs;
                var business_calls_array = [];
                var mwb_call_count = 0;
                var mwb_connected_call_count = 0;
                var ewb_connected_call_count = 0;
                var ewb_call_count = 0;
                var untracked_in_calls_count = 0;
                var untracked_in_calls_duration = 0;
                var untracked_out_calls_count = 0;
                var untracked_out_calls_duration = 0;
                var incoming_call_count = 0;
                var outgoing_call_count = 0;
                var missed_call_count = 0;
                var call_30_60 = 0;
                var call_gt_60 = 0;
                var enrollments = [];
                var total_color = "";
                var nuc_count = 0;
                var nuc_duration = 0;
                var training = utilization_data[i].attendance.filter(function (
                    eachatt
                ) {
                    return eachatt.type === "training";
                });
                var training_utilization = this.getTrainingUtilization(
                    training,
                    utilization_data[i].attendance
                );
                var mwb_arr = [];
                var ewb_arr = [];
                var missed_calls_arr = [];
                var nuc_arr = [];
                var average = 0;
                var visitaverage = 0;
                let connected_calls = 0;
                let connected_calls_arr = [];
                let ewb_connected_calls = 0;
                let ewb_connected_calls_arr = [];
                let ewb_business_calls_array = [];
                let mwb_connected_calls = 0;
                let mwb_connected_calls_arr = [];
                let mwb_business_calls_array = [];
                let enrollments_without_pending_payments = [];
                utilization_data[i].actual_enrollments.forEach((enrollment) => {
                    let payment_status = this.getPaymentStatus(enrollment);
                    if (payment_status === 2) {
                        enrollments_without_pending_payments.push(enrollment);
                    }
                });
                // console.log(utilization_data[i]);
                if (i === 0) {
                    // console.log(utilization_data[i].visit_engagements);
                }
                utilization_data[i].visit_engagements.forEach(
                    (visit_engagment) => {
                        all_visit_engagments.push(visit_engagment);
                    }
                );
                if (
                    this.timetoTimestamp(this.today_date.startDate) !==
                    this.timetoTimestamp(this.today_date.endDate)
                ) {
                    average = this.getCallAverage(
                        all_call_logs,
                        utilization_data[i].attendance
                    );
                    visitaverage = this.getVisitAverage(
                        utilization_data[i].visit_engagements,
                        utilization_data[i].attendance
                    );
                }
                // if (utilization_data[i]["all_call_logs"][j].directory == "MISSED") {
                //       if (utilization_data[i]["all_call_logs"][j].updated === 0) {
                //       missed_call_count++;
                //       missed_calls_arr.push(utilization_data[i]["all_call_logs"][j]);
                //       // console.log(utilization_data[i]["all_call_logs"][j]);
                //       }
                //     }
                utilization_data[i]["visit_engagements"].forEach(
                    (visit_engagement) => {
                        if (visit_engagement.visit_status === "Enrollment") {
                            // console.log(visit_engagement)
                            enrollments.push(visit_engagement);
                        }
                    }
                );
                for (
                    var j = 0;
                    j < utilization_data[i]["all_call_logs"].length;
                    j++
                ) {
                    utilization_data[i]["all_call_logs"][j].spoc_name =
                        utilization_data[i].spoc_name;
                    if (
                        utilization_data[i]["all_call_logs"][j].contact_type ==
                            "B2C" ||
                        utilization_data[i]["all_call_logs"][j].contact_type ==
                            "B2BIR" ||
                        utilization_data[i]["all_call_logs"][j].contact_type ==
                            "B2BCR"
                    ) {
                        if (
                            utilization_data[i]["all_call_logs"][j].updated == 1
                        ) {
                            business_calls_array.push(
                                utilization_data[i]["all_call_logs"][j]
                            );
                            call_duration_sec +=
                                utilization_data[i]["all_call_logs"][j]
                                    .call_duration;
                            total_calls++;
                            if (
                                utilization_data[i]["all_call_logs"][j]
                                    .directory === "INCOMING"
                            ) {
                                incoming_call_count++;
                            } else if (
                                utilization_data[i]["all_call_logs"][j]
                                    .directory === "OUTGOING"
                            ) {
                                outgoing_call_count++;
                            }
                            // console.log(
                            //   utilization_data[i]["all_call_logs"][j].call_duration
                            // );
                            if (
                                utilization_data[i]["all_call_logs"][j]
                                    .call_duration > 0
                            ) {
                                connected_calls++;
                                connected_calls_arr.push(
                                    utilization_data[i]["all_call_logs"][j]
                                );
                            }
                            if (
                                utilization_data[i]["all_call_logs"][j].level ==
                                "M7"
                            ) {
                                ewb_call_count++;
                                ewb_arr.push(
                                    utilization_data[i]["all_call_logs"][j]
                                );
                                if (
                                    utilization_data[i]["all_call_logs"][j]
                                        .call_duration > 0
                                ) {
                                    ewb_connected_call_count++;
                                    ewb_connected_calls_arr.push(
                                        utilization_data[i]["all_call_logs"][j]
                                    );
                                }
                                if (
                                    utilization_data[i]["all_call_logs"][j]
                                        .updated == 1
                                ) {
                                    ewb_business_calls_array.push(
                                        utilization_data[i]["all_call_logs"][j]
                                    );
                                }
                            } else {
                                mwb_call_count++;
                                mwb_arr.push(
                                    utilization_data[i]["all_call_logs"][j]
                                );
                                if (
                                    utilization_data[i]["all_call_logs"][j]
                                        .call_duration > 0
                                ) {
                                    mwb_connected_call_count++;
                                    mwb_connected_calls++;
                                    mwb_connected_calls_arr.push(
                                        utilization_data[i]["all_call_logs"][j]
                                    );
                                }
                                if (
                                    utilization_data[i]["all_call_logs"][j]
                                        .updated == 1
                                ) {
                                    mwb_business_calls_array.push(
                                        utilization_data[i]["all_call_logs"][j]
                                    );
                                }
                            }
                            if (
                                utilization_data[i]["all_call_logs"][j]
                                    .call_duration >= 1800 &&
                                utilization_data[i]["all_call_logs"][j]
                                    .call_duration < 3600
                            ) {
                                call_30_60++;
                            } else if (
                                utilization_data[i]["all_call_logs"][j]
                                    .call_duration >= 3600
                            ) {
                                call_gt_60++;
                            }
                        }
                        // if (utilization_data[i]["all_call_logs"][j].directory == "MISSED") {
                        //   if (utilization_data[i]["all_call_logs"][j].updated === 0) {
                        //   missed_call_count++;
                        //   missed_calls_arr.push(utilization_data[i]["all_call_logs"][j]);
                        //   // console.log(utilization_data[i]["all_call_logs"][j]);
                        //   }
                        // }
                    } else if (
                        utilization_data[i]["all_call_logs"][j].contact_type ==
                        "untracked"
                    ) {
                        if (
                            utilization_data[i]["all_call_logs"][j][
                                "directory"
                            ] == "INCOMING"
                        ) {
                            untracked_in_calls_count++;
                            untracked_in_calls_duration =
                                untracked_in_calls_duration +
                                utilization_data[i]["all_call_logs"][j][
                                    "call_duration"
                                ];
                        } else if (
                            utilization_data[i]["all_call_logs"][j][
                                "directory"
                            ] == "OUTGOING"
                        ) {
                            untracked_out_calls_count++;
                            untracked_out_calls_duration =
                                untracked_out_calls_duration +
                                utilization_data[i]["all_call_logs"][j][
                                    "call_duration"
                                ];
                        }
                    } else {
                        nuc_count++;
                        nuc_arr.push(utilization_data[i]["all_call_logs"][j]);
                        nuc_duration =
                            nuc_duration +
                            utilization_data[i]["all_call_logs"][j]
                                .call_duration;
                    }
                }
                utilization_data[i].all_call_logs.forEach((call) => {
                    if (call.directory === "MISSED") {
                        if (
                            call.contact_type === "untracked" ||
                            call.contact_type === "B2C"
                        ) {
                            if (call.updated === 0) {
                                missed_call_count++;
                                missed_calls_arr.push(call);
                            }
                        }
                    }
                });
                var unique_calls_array = [
                    ...new Set(
                        business_calls_array.map((item) => item.person_id)
                    ),
                ];
                var ewb_unique_calls_array = [
                    ...new Set(
                        ewb_business_calls_array.map((item) => item.person_id)
                    ),
                ];
                var mwb_unique_calls_array = [
                    ...new Set(
                        mwb_business_calls_array.map((item) => item.person_id)
                    ),
                ];
                var call_duration_mins = Math.round(call_duration_sec / 60);
                var call_utilization = Math.round(
                    (call_duration_mins * 100) / 180
                );
                var visitor_utilization = 0;
                if (utilization_data[i]["visit_engagements"].length > 0) {
                    var first_visit_count = 0;
                    var enrollment_visit_count = 0;
                    var repeat_visit_count = 0;
                    for (
                        var k = 0;
                        k < utilization_data[i]["visit_engagements"].length;
                        k++
                    ) {
                        if (
                            utilization_data[i]["visit_engagements"][k][
                                "visit_status"
                            ] === "First"
                        ) {
                            //12.5%
                            first_visit_count++;
                        } else if (
                            utilization_data[i]["visit_engagements"][k][
                                "visit_status"
                            ] === "Enrollment"
                        ) {
                            //6.25%
                            if (
                                utilization_data[i]["visit_engagements"][k][
                                    "visit_status"
                                ] === "Enrollment" &&
                                utilization_data[i]["visit_engagements"][k][
                                    "visit_count"
                                ] === 1
                            ) {
                                first_visit_count++;
                            } else {
                                enrollment_visit_count++;
                            }
                        } else if (
                            utilization_data[i]["visit_engagements"][k][
                                "visit_status"
                            ] === "Repeat"
                        ) {
                            //3.125%
                            repeat_visit_count++;
                        }
                    }
                    visitor_utilization =
                        first_visit_count * 12.5 +
                        enrollment_visit_count * 6.25 +
                        repeat_visit_count * 3.125;
                }
                utilization_data[i].incoming_call_count = incoming_call_count;
                utilization_data[i].outgoing_call_count = outgoing_call_count;
                utilization_data[i].enrollments = enrollments;
                utilization_data[i].call_duration_sec = call_duration_sec;
                utilization_data[i].training = training;
                utilization_data[i].call_utilization = call_utilization + "%";
                utilization_data[i].visitor_utilization =
                    visitor_utilization + "%";
                utilization_data[i].training_utilization =
                    training_utilization + "%";
                utilization_data[i].total_utilization =
                    Math.round(call_utilization + visitor_utilization) + "%";
                if (call_utilization + visitor_utilization >= 100) {
                    total_color = "success";
                } else if (
                    call_utilization + visitor_utilization >= 80 &&
                    call_utilization + visitor_utilization < 100
                ) {
                    total_color = "warning";
                } else if (call_utilization + visitor_utilization < 80) {
                    total_color = "danger";
                }
                if (
                    this.timetoTimestamp(this.today_date.startDate) !==
                    this.timetoTimestamp(this.today_date.endDate)
                ) {
                    // console.log(average);
                    utilization_data[i].call_utilization = average + "%";
                    utilization_data[i].visitor_utilization =
                        visitaverage + "%";
                    utilization_data[i].total_utilization =
                        Math.round(average + visitaverage) + "%";
                    if (average + visitaverage >= 100) {
                        total_color = "success";
                    } else if (
                        average + visitaverage >= 80 &&
                        average + visitaverage < 100
                    ) {
                        total_color = "warning";
                    } else if (average + visitaverage < 80) {
                        total_color = "danger";
                    }
                }
                utilization_data[i].total_calls = total_calls;
                utilization_data[i].total_unique_calls =
                    unique_calls_array.length;
                utilization_data[i].total_ewb_unique_calls =
                    ewb_unique_calls_array.length;
                utilization_data[i].total_mwb_unique_calls =
                    mwb_unique_calls_array.length;
                utilization_data[i].total_duration = this.secondsToHms(
                    call_duration_sec
                );
                utilization_data[i].total_color = total_color;
                utilization_data[
                    i
                ].mwb_connected_call_count = mwb_connected_call_count;
                utilization_data[
                    i
                ].ewb_connected_call_count = ewb_connected_call_count;
                utilization_data[
                    i
                ].enrollments_without_pending_payments = enrollments_without_pending_payments;
                utilization_data[i].mwb_call_count = mwb_call_count;
                utilization_data[i].connected_calls = connected_calls;
                utilization_data[i].connected_calls_arr = connected_calls_arr;
                utilization_data[i].ewb_connected_calls = ewb_connected_calls;
                utilization_data[
                    i
                ].ewb_connected_calls_arr = ewb_connected_calls_arr;
                utilization_data[i].mwb_connected_calls = mwb_connected_calls;
                utilization_data[
                    i
                ].mwb_connected_calls_arr = mwb_connected_calls_arr;
                utilization_data[i].mwb_arr = mwb_arr;
                utilization_data[i].ewb_call_count = ewb_call_count;
                utilization_data[i].ewb_arr = ewb_arr;
                utilization_data[
                    i
                ].untracked_in_calls_count = untracked_in_calls_count;
                utilization_data[
                    i
                ].untracked_in_calls_duration = this.secondsToHms(
                    untracked_in_calls_duration
                );
                utilization_data[
                    i
                ].untracked_out_calls_count = untracked_out_calls_count;
                utilization_data[
                    i
                ].untracked_out_calls_duration = this.secondsToHms(
                    untracked_out_calls_duration
                );
                utilization_data[i].missed_call_count = missed_call_count;
                utilization_data[i].missed_calls_arr = missed_calls_arr;
                utilization_data[i].call_30_60 = call_30_60;
                utilization_data[i].call_gt_60 = call_gt_60;
                utilization_data[i].nuc_count = nuc_count;
                utilization_data[i].nuc_arr = nuc_arr;
                utilization_data[i].nuc_duration = this.secondsToHms(
                    nuc_duration
                );
            }
            utilization_data.forEach((spoc) => {
                spoc.visit_count = this.getMyVisitCount(
                    spoc.spoc_id,
                    all_visit_engagments
                );
            });
            this.utilization_data = utilization_data;
            console.log(utilization_data);
            this.loaded = true;
            this.$vs.loading.close();
        },
    
    
    giveAttendanceTitle(item) {
      // console.log(item.attendance);
      if (item.attendance.length > 0) {
        if(item.attendance[0].status == null){
          item.attendance[0].status = "";
          return this.formattedAttendance(item.attendance[0].status);
        } else{
          return this.formattedAttendance(item.attendance[0].status);
        }
        // return this.formattedAttendance(item.attendance[0].status);
      } else {
        return "";
      }
    },
    formattedAttendance(word) {
      let replced = word.replace(/_/g, " ");
      let formatted = replced
        .toLowerCase()
        .split(" ")
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(" ");
      return formatted;
    },
    getMyVisitCount(id, visitArray) {
      let visits_by_id = [];
      visitArray.forEach((visit_engagement) => {
        if (visit_engagement.assigned_spoc_id === id) {
          visits_by_id.push(visit_engagement);
        }
      });
      return visits_by_id.length;
    },
    calculateAverage(arrpercentage, attendance) {
      let add = 0;
      // let present = attendance.filter(function(att) {
      //   return att.type === "present";
      // });
      let present = [];
      attendance.forEach((day) => {
        day.formatted_date = moment
          .unix(day.attendance_date)
          .format("YYYY MM DD");
        // console.log(day.formatted_date);
        if (
          present.indexOf(day.formatted_date) === -1 &&
          day.status === "present"
        ) {
          present.push(day.formatted_date);
        }
      });
      arrpercentage.forEach((arr) => {
        add = add + arr;
      });
      // console.log(present);
      let ave = Math.round(add / present.length);
      if (!isFinite(ave)) {
        return "NA";
      } else {
        return ave;
      }
      // return Math.round(add / present.length);
    },
    getCallAverage(callLogs, attendance) {
      // console.log("called for average");
      let arrpercentage = this.getPercentageByDay(callLogs);
      let average = this.calculateAverage(arrpercentage, attendance);
      if (isNaN(average)) {
        return 0;
      } else {
        return average;
      }
    },
    getPercentageByDay(callLogs) {
      let time = [];
      let ByDayData = [];
      callLogs.forEach((log) => {
        log.updated_at_date = moment.unix(log.time / 1000).format("DD-MM-YYYY");
      });
      var unique = callLogs
        .map((name) => {
          return {
            count: 1,
            name: name.updated_at_date,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          time.push(key);
        }
      }
      time.forEach((time) => {
        let obj = {
          time: time,
          data: [],
        };
        callLogs.forEach((log) => {
          if (log.updated_at_date === time) {
            obj.data.push(log);
          }
        });
        ByDayData.push(obj);
      });
      // console.log(`USER ID => ${callLogs[0].user_id}`);
      // console.log(unique);
      // console.log(ByDayData);
      let percentage = [];
      ByDayData.forEach((dayData) => {
        let value = this.getPercentage(dayData.data);
        percentage.push(value);
      });
      // console.log(percentage);
      return percentage;
    },
    getPercentage(callLogs) {
      let percentage = 0;
      let seconds = 0;
      callLogs.forEach((log) => {
        if (
          log.contact_type === "B2C" ||
          log.contact_type === "B2BIR" ||
          log.contact_type === "B2BCR"
        ) {
          if (log.updated == 1) {
            seconds = seconds + log.call_duration;
          }
        }
      });
      let minutes = Math.round(seconds / 60);
      percentage = Math.round((minutes * 100) / 180);
      return percentage;
    },
    getVisitAverage(engagements, attendance) {
      // console.log(engagements);
      let arr = this.getVisitpercentageperday(engagements);
      let average = this.calculateAverage(arr, attendance);
      // console.log(average);
      if (isNaN(average)) {
        return 0;
      } else {
        return average;
      }
    },
    getVisitpercentageperday(engagements) {
      let time = [];
      let ByDayData = [];
      engagements.forEach((piece) => {
        piece.date_for_calc = piece.created_at.split(" ")[0];
      });
      var unique = engagements
        .map((name) => {
          return {
            count: 1,
            name: name.date_for_calc,
          };
        })
        .reduce((a, b) => {
          a[b.name] = (a[b.name] || 0) + b.count;
          return a;
        }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          time.push(key);
        }
      }
      time.forEach((time) => {
        let obj = {
          time: time,
          data: [],
        };
        engagements.forEach((log) => {
          if (log.date_for_calc === time) {
            obj.data.push(log);
          }
        });
        ByDayData.push(obj);
      });
      // console.log(unique);
      // console.log(ByDayData);
      let percentage = [];
      ByDayData.forEach((dayData) => {
        let value = this.getvisitPercentage(dayData.data);
        percentage.push(value);
      });
      // console.log(percentage);
      return percentage;
    },
    getvisitPercentage(daydata) {
      let percentage = 0;
      daydata.forEach((day) => {
        if (day.visit_status === "First") {
          percentage = percentage + 12.5;
        } else if (day.visit_status === "Enrollment") {
          percentage = percentage + 6.25;
        } else if (day.visit_status === "Repeat") {
          percentage = percentage + 3.125;
        }
      });
      return percentage;
    },
    getTrainingUtilization(TrainingData, attendance) {
      if (
        this.timetoTimestamp(this.today_date.startDate) !==
        this.timetoTimestamp(this.today_date.endDate)
      ) {
        let utilization = this.getTrainingAverage(TrainingData, attendance);
        if (isNaN(utilization) !== true) {
          return utilization;
        } else {
          return 0;
        }
      } else {
        if (TrainingData.length !== 0) {
          let utilization = this.getTrainingPercentage(TrainingData);
          return utilization;
        } else {
          return 0;
        }
      }
    },
    getTrainingAverage(TrainingData, attendance) {
      let getByday = this.getTrainingDayAverage(TrainingData);
      let average = this.calculateAverage(getByday, attendance);
      return average;
    },
    getTrainingDayAverage(TrainingData) {
      let time = [];
      let ByDayData = [];
      TrainingData.forEach((piece) => {
        piece.date_for_calc = piece.created_at.split(" ")[0];
      });
      var unique = TrainingData.map((name) => {
        return {
          count: 1,
          name: name.date_for_calc,
        };
      }).reduce((a, b) => {
        a[b.name] = (a[b.name] || 0) + b.count;
        return a;
      }, {});
      for (var key in unique) {
        if (unique.hasOwnProperty(key)) {
          time.push(key);
        }
      }
      time.forEach((time) => {
        let obj = {
          time: time,
          data: [],
        };
        TrainingData.forEach((log) => {
          if (log.date_for_calc === time) {
            obj.data.push(log);
          }
        });
        ByDayData.push(obj);
      });
      let percentage = [];
      ByDayData.forEach((dayData) => {
        let value = this.getTrainingPercentage(dayData.data);
        percentage.push(value);
      });
      return percentage;
    },
    getTrainingPercentage(data) {
      let minutes = 0;
      data.forEach((datapoint) => {
        minutes = minutes + datapoint.minutes;
      });
      let percentage = (minutes / 60) * 12.5;
      return Math.round(percentage);
    },
    secondsToHms(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor((d % 3600) / 60);
      var s = Math.floor((d % 3600) % 60);
      return (
        ("0" + h).slice(-2) +
        ":" +
        ("0" + m).slice(-2) +
        ":" +
        ("0" + s).slice(-2)
      );
    },
    getselectedspoc(obj) {
      router.push({ name: "CallLogs", params: { selected_obj: obj } });
    },
  },
};
</script>
<style>
.vs--searchable .vs__dropdown-toggle {
  height: 38px;
}
</style>